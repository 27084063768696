import React from 'react';
import styles from '../Dropdown.module.scss';
import { useDropdownContext } from '../context/DropdownContext';
import { DropdownType, DropdownListItemType } from '../types/DropdownType';
// @ts-ignore
import classNames from 'classnames';

type DropdownListPropsType = {
    /**
     * DropdownListItemType[]
    */
    list?: DropdownListItemType[],
    classNames?: string,
    /**
     * (item: DropdownListItemType) => void;
    */
    onClick?: (item: DropdownListItemType) => void;
}

const DropdownList = (props: DropdownType & DropdownListPropsType) => {
    const { isShowing, isAppendToBody, toggle } = useDropdownContext()

    const handleClick = (item: DropdownListItemType) => {
        if (toggle) {
            toggle();
        }

        if (props.onClick) {
            props.onClick(item);
        }
    };

    return (
        <ul 
            data-testid="dropdown-list"
            data-ref="dropdownList"
            className={classNames(
                styles.dropdownList,
                props.classNames,
                (isShowing && !isAppendToBody) && styles.dropdownListIsVisible
            )}
        >
            {props.list ? (
                props.list.map((item) => {
                    return (
                        <li 
                            key={item.value} 
                            className={classNames(
                                styles.dropdownListItem,
                                item.className
                            )} 
                            onClick={() => handleClick(item)}
                        >
                            {item.name}
                        </li>
                    )
                })
            ) : (
                props.children
            )}
        </ul>
    )
}

export default React.memo(DropdownList);