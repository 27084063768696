import React, { useState, useEffect } from 'react';
import { AccordionContext } from './context/AccordionContext';
import styles from './Accordion.module.scss';
import AccordionHeader from './components/AccordionHeader';
import AccordionBody from './components/AccordionBody';
// @ts-ignore
import classNames from 'classnames';
import AccordionItem from './components/AccordionItem';

type AccordionPropsType = {
    children: React.ReactNode,
    className?: string,
    currentOpen?: number | null,
    alwaysOpen?: boolean,
    onToggle: (index: number) => void
}

const Accordion = ({ alwaysOpen = false, ...props }: AccordionPropsType) => {
    
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [currentOpen, setCurrentOpen] = useState<number>(props.currentOpen || 0);

    const toggle = (currentIndex: number) => {
        if (currentIndex !== currentOpen) {
            setCurrentOpen(currentIndex);
            setIsOpen(true);
        } else if (!alwaysOpen) {
            setIsOpen(!isOpen)
        }

        props.onToggle(currentIndex);
    };

    useEffect(() => {
        if (props.currentOpen || props.currentOpen === 0) {
            setIsOpen(true);
            setCurrentOpen(props.currentOpen);
        }

    }, [props.currentOpen]);

    return (
        <AccordionContext.Provider value={{
            isOpen,
            toggle,
            currentOpen
        }}>
            <div className={classNames(styles.wrapper, props.className)}>
                {props.children}
            </div>
        </AccordionContext.Provider>
    )
}

Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;
Accordion.Item = AccordionItem;

export default Accordion;