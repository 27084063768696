import ApplicationSettings from '../../modules/ApplicationSettings/ApplicationSettings';
import { InvoiceDetailsTabs } from '../../modules/InvoiceDetailsTabs/InvoiceDetailsTabs';
import ActivitiesList from '../../modules/ActivitiesList/ActivitiesList';
import CaseList from '../../modules/CaseList/CaseList';
import CasePayments from '../../modules/CasePayments/CasePayments';
import CaseStatisticsCasesOverdue from '../../modules/CaseStatisticsCasesOverdue/CaseStatisticsCasesOverdue';
import CaseStatisticsInvoiceAging from '../../modules/CaseStatisticsInvoiceAging/CaseStatisticsInvoiceAging';
import CaseStatisticsPortfolio from '../../modules/CaseStatisticsPortfolio/CaseStatisticsPortfolio';
import CaseStatisticsPortfolioAmounts from '../../modules/CaseStatisticsPortfolioAmounts/CaseStatisticsPortfolioAmounts';
import CaseStatisticsSumOfAllCases from '../../modules/CaseStatisticsSumOfAllCases/CaseStatisticsSumOfAllCases';
import CaseSummary from '../../modules/CaseSummary/CaseSummary';
import CaseTabs from '../../modules/CaseTabs/CaseTabs';
import CaseTimeline from '../../modules/CaseTimeline/CaseTimeline';
import Cashflow from '../../modules/Cashflow/Cashflow';
import ChangePassword from '../../modules/ChangePassword/ChangePassword';
import Chat from '../../modules/Chat/Chat';
import CookieDisclaimer from '../../modules/CookieDisclaimer/CookieDisclaimer';
import CreateNewCase from '../../modules/CreateNewCase/CreateNewCase';
import CreditorRegistration from '../../modules/CreditorRegistration/CreditorRegistration';
import CreditorsAssignment from '../../modules/CreditorsAssignment/CreditorsAssignment';
import CreditorsManagment from '../../modules/CreditorsManagment/CreditorsManagment';
import CreditorOnboardingAgreements from '../../modules/CreditorOnboardingAgreements/CreditorOnboardingAgreements';
import CreditorsVerifycation from '../../modules/CreditorsVerifycation/CreditorsVerifycation';
import CustomerTransactionsList from '../../modules/CustomerTransactionsList/CustomerTransactionsList';
import DebtorDetails from '../../modules/DebtorDetails/DebtorDetails';
import DirectPayment from '../../modules/DirectPayment/DirectPayment';
import DnnMigration from '../../modules/DnnMigration/DnnMigration';
import DocumentsList from '../../modules/DocumentsList/DocumentsList';
import ExternalLoginProviders from '../../modules/ExternalLoginProviders/ExternalLoginProviders';
import GeneratedReports from '../../modules/GeneratedReports/GeneratedReports';
import GeneratedScheduleReports from '../../modules/GeneratedScheduleReports/GeneratedScheduleReports';
import GoogleAnalytics from '../../modules/GoogleAnalytics/GoogleAnalytics';
import GroupUsersAssignment from '../../modules/GroupUsersAssignment/GroupUsersAssignment';
import GroupUsersList from '../../modules/GroupUsersList/GroupUsersList';
import Guarantors from '../../modules/Guarantors/Guarantors';
import MessagesHome from '../../modules/HomeMessages/HomeMessages';
import InvoiceDetails from '../../modules/InvoiceDetails/InvoiceDetails';
import InvoiceDetailsActivities from '../../modules/InvoiceDetailsActivities/InvoiceDetailsActivities';
import InvoiceDetailsDirectPayment from '../../modules/InvoiceDetailsDirectPayment/InvoiceDetailsDirectPayment';
import InvoiceDetailsMessages from '../../modules/InvoiceDetailsMessages/InvoiceDetailsMessages';
import InvoiceDetailsNotes from '../../modules/InvoiceDetailsNotes/InvoiceDetailsNotes';
import InvoiceDetailsPayments from '../../modules/InvoiceDetailsPayments/InvoiceDetailsPayments';
import InvoicesAgeAnalysis from '../../modules/InvoicesAgeAnalysis/InvoicesAgeAnalysis';
import InvoicesList from '../../modules/InvoicesList/InvoicesList';
import LogoBanner from '../../modules/LogoBanner/LogoBanner';
import ManageModulesInstances from '../../modules/ManageModulesInstances/ManageModulesInstances';
import Messages from '../../modules/Messages/Messages';
import MessageTemplatesManagement from '../../modules/MessageTemplatesManagement/MessageTemplatesManagement';
import Modal from '../../modules/Modal/Modal';
import ModuleSettings from '../../modules/ModuleSettings/ModuleSettings';
import NavbarSearchForm from '../../modules/NavbarSearchForm/NavbarSearchForm';
import NewUserBox from '../../modules/NewUserBox/NewUserBox';
import NotesList from '../../modules/NotesList/NotesList';
import OnlineReports from '../../modules/OnlineReports/OnlineReports';
import OrderPaper from '../../modules/Customers/OrderPaper/OrderPaper';
import PagesList from '../../modules/PagesList/PagesList';
import Payments from '../../modules/Payments/Payments';
import PortalUsersList from '../../modules/PortalUsersList/PortalUsersList';
import PostponeInvoice from '../../modules/PostponeInvoice/PostponeInvoice';
import PrintCase from '../../modules/PrintCase/PrintCase';
import RegisterUser from '../../modules/RegisterUser/RegisterUser';
import ReportViewer from '../../modules/ReportViewer/ReportViewer';
import RolesAssignment from '../../modules/RolesAssignment/RolesAssignment';
import RolesManagement from '../../modules/RolesManagement/RolesManagement';
import ScheduleReport from '../../modules/ScheduleReport/ScheduleReport';
import ScheduleReports from '../../modules/ScheduleReports/ScheduleReports';
import SimulatedBalance from '../../modules/SimulatedBalance/SimulatedBalance';
import StylingSettings from '../../modules/StylingSettings/StylingSettings';
import Tiles from '../../modules/Tiles/Tiles';
import Topbar from '../../modules/Topbar/Topbar';
import TransferToDebtCollection from '../../modules/TransferToDebtCollection/TransferToDebtCollection';
import TranslationsManagement from '../../modules/TranslationsManagement/TranslationsManagement';
import UnitCases from '../../modules/Customers/UnitCases/UnitCases';
import UnitIdSearch from '../../modules/UnitIdSearch/UnitIdSearch';
import UnitInvoices from '../../modules/Customers/UnitInvoices/UnitInvoices';
import UnitOverview from '../../modules/Customers/UnitOverview/UnitOverview';
import UserProfile from '../../modules/UserProfile/UserProfile';
import Users from '../../modules/Users/Users';
import UsersStatistics from '../../modules/UsersStatistics/UsersStatistics';
import Welcome from '../../modules/Welcome/Welcome';
import WithdrawCase from '../../modules/WithdrawCase/WithdrawCase';
import DcaRegisterUser from '../../modules/DcaRegisterUser/DcaRegisterUser';
import CreditorFileUpload from '../../modules/CreditorFileUpload/CreditorFileUpload';
import ManageLeftMenu from '../../modules/ManageLeftMenu/ManageLeftMenu';
import UserCaseNotes from '../../modules/UserCaseNotes/UserCaseNotes';
import CreditorAccountSummary from 'modules/Customers/CreditorAccountSummary/CreditorAccountSummary';

export default {
    ActivitiesList,
    ApplicationSettings,
    CaseList,
    CasePayments,
    CaseStatisticsCasesOverdue,
    CaseStatisticsInvoiceAging,
    CaseStatisticsPortfolio,
    CaseStatisticsPortfolioAmounts,
    CaseStatisticsSumOfAllCases,
    CaseSummary,
    CaseTabs,
    CaseTimeline,
    Cashflow,
    ChangePassword,
    Chat,
    CookieDisclaimer,
    CreateNewCase,
    CreditorAccountSummary,
    CreditorOnboardingAgreements,
    CreditorRegistration,
    CreditorsAssignment,
    CreditorFileUpload,
    CreditorsManagment,
    CreditorsVerifycation,
    CustomerTransactionsList,
    DcaRegisterUser,
    DebtorDetails,
    DirectPayment,
    DnnMigration,
    DocumentsList,
    ExternalLoginProviders,
    GeneratedReports,
    GeneratedScheduleReports,
    GoogleAnalytics,
    GroupUsersAssignment,
    GroupUsersList,
    Guarantors,
    InvoiceDetails,
    InvoiceDetailsActivities,
    InvoiceDetailsDirectPayment,
    InvoiceDetailsMessages,
    InvoiceDetailsNotes,
    InvoiceDetailsPayments,
    InvoiceDetailsTabs,
    InvoicesAgeAnalysis,
    InvoicesList,
    LogoBanner,
    ManageLeftMenu,
    ManageModulesInstances,
    Messages,
    MessageTemplatesManagement,
    MessagesHome,
    Modal,
    ModuleSettings,
    NavbarSearchForm,
    NewUserBox,
    NotesList,
    OnlineReports,
    OrderPaper,
    PagesList,
    Payments,
    PortalUsersList,
    PostponeInvoice,
    PrintCase,
    RegisterUser,
    ReportViewer,
    RolesAssignment,
    RolesManagement,
    ScheduleReport,
    ScheduleReports,
    SimulatedBalance,
    StylingSettings,
    Tiles,
    Topbar,
    TransferToDebtCollection,
    TranslationsManagement,
    UnitCases,
    UnitIdSearch,
    UnitInvoices,
    UnitOverview,
    UserProfile,
    Users,
    UserCaseNotes,
    UsersStatistics,
    Welcome,
    WithdrawCase,
};
