import * as React from 'react';
import TranslationService from '../../core/services/TranslationService';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import './_WithdrawCase.scss';
import WithdrawCaseService from './services/WithdrawCaseService';
import Loader from '../../core/components/Loading/Loader';
import InfoMessageService from '../../core/services/InfoMessageService';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { addFromUserPrefix } from '../../core/helpers/utils';

interface IState {
  isLoading: boolean;
  note: string;
  errors: string[];
}

export default class WithdrawCase extends React.Component<IExtendedModuleProps, IState> {
  public state: IState = {
    isLoading: false,
    note: '',
    errors: [],
  };

  private module: any = flatModuleOptions<any>(this.props.module);

  public render() {
    return (
      <div className="container withdraw-case">
        {this.state.isLoading ? <Loader opacity={1} /> : null}
        <div className="row">
          <div className="col-12">
            <span>
              {TranslationService.translateModule('WithdrawCaseNoteTitle', this.props.module.name)}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <textarea
              id="withDrawTaskNote"
              className="form-control"
              maxLength={parseInt(this.module.MaxNumberOfCharacters, 10)}
              onChange={this.onNoteChange}
            />
            <div className={`validation-errors ${!this.state.errors.length ? 'd-none' : null}`}>
              {this.state.errors.map((err, index) => (
                <span key={index}>{err}</span>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="action-buttons">
              <button
                id="withDrawBtn"
                type="button"
                className="btn btn-default"
                onClick={this.withdrawCase}
              >
                <i className="fas fa-ban" />{' '}
                {TranslationService.translateModule('Withdraw', this.props.module.name)}
              </button>

              <button
                id="withDrawCancelBtn"
                type="button"
                className="btn btn-default btn-cancel"
                onClick={this.onCancel}
              >
                <i className="fas fa-times" /> {TranslationService.translate('Cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onNoteChange = (event: any) => {
    const valid = this.validate(event.target.value);

    if (valid) {
      this.setState({
        note: event.target.value,
      });
    }
  };

  private validate = (note: string) => {
    const errors = [];
    if (note.length > this.module.MaxNumberOfCharacters) {
      errors.push(
        `${TranslationService.translateModule(
          'WithdrawCaseNoteTooLongError',
          this.props.module.name
        )} ${this.module.MaxNumberOfCharacters}`
      );
    }

    this.setState({
      errors,
    });

    return true;
  };

  private withdrawCase = async () => {
    if (this.state.errors.length || !this.validate(this.state.note)) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    try {
      await WithdrawCaseService.withdrawCase({
        caseId: this.props.caseId,
        moduleInstanceId: this.props.module.id,
        note: `${addFromUserPrefix()} ${this.state.note}`,
      });
      InfoMessageService.success(
        TranslationService.translateModule('WithdrawCaseSuccess', this.props.module.name)
      );
      this.callExitHandler(true);
    } catch (err: any) {
      InfoMessageService.error(
        TranslationService.translateModule('WithdrawCaseError', this.props.module.name)
      );
    }

    this.setState({
      isLoading: false,
    });
  };

  private onCancel = () => {
    this.callExitHandler(false);
  };

  private callExitHandler = (actionSuccess: boolean) => {
    if (this.props.exit) {
      this.props.exit(actionSuccess);
    }
  };
}
