import React from 'react';

type ContextProps = { 
    isShowing: boolean,
    isAppendToBody: boolean,
    toggle: () => void
}
const DropdownContext = React.createContext<Partial<ContextProps>>({});

const useDropdownContext = () => {
    const context = React.useContext(DropdownContext);

    if (!context) {
      throw new Error(
        `Dropdown compound components cannot be rendered outside the Dropdown component`,
      )
    }

    return context
}

export {
    DropdownContext,
    useDropdownContext
} 