import React from 'react';
import styles from './TextValue.module.scss';
// @ts-ignore
import classNames from 'classnames';

type PropsType = {
    label: string,
    value?: string,
    className?: string,
    children?: React.ReactNode
}

const TextValue = (props: PropsType) => {
    return (
        <div data-testid="textValue-wrapper" className={classNames(styles.wrapper, props.className)}>
            <p className={classNames(styles.label)}>
                {props.label}
            </p>

            {props.children ? (
                <div>{props.children}</div>
            ) : (
                <p className={classNames(styles.value)}>
                    {props.value}
                </p>
            )}
        </div>
    );
};

export default TextValue;