import React from 'react';
import styles from '../Accordion.module.scss';
// @ts-ignore
import classNames from 'classnames';

type AccordionItemPropsType = {
    className?: string,
    children: React.ReactNode
}

const AccordionItem = (props: AccordionItemPropsType) => {
    return (
        <div className={classNames(
            styles.item,
            props.className
        )}>{props.children}</div>
    )
}

export default AccordionItem;