import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import ScheduleReportApiClient from './ScheduleReportApiClient';
import RouteParamsService from '../../core/services/RouteParamsService';
import TranslationService from '../../core/services/TranslationService';

import MultiStepForm from '../../core/components/MultiStepForm/MultiStepForm';
import StepForm from '../../core/components/MultiStepForm/StepForm';
import Loader from './../../core/components/Loading/Loader';

import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IStepListItem } from '../StepListWizard/types/IStepListItem';
import { INumberDictionary } from '../../core/types/IDictionary';
import { StepIconsCss } from '../StepListWizard/types/StepIconCss';
import { CreateReportScheduleSteps } from './CreateReportScheduleSteps';
import { ISubscriptionDataVM } from './types/ISubscriptionDataVM';
import { IScheduleData } from '../Reports/types/IScheduleData';
import { IOnlineReportsFolder } from '../Reports/types/IOnlineReportsFolder';
import { IOnlineReport } from '../Reports/types/IOnlineReport';
import { IScheduleReportVm } from './types/IScheduleReportVm';

import ReportType from '../Reports/components/ReportType';
import ReportParameters from '../Reports/components/ReportParameters';
import ScheduleFrequency from './components/ScheduleFrequency';
import ScheduleFormats from './components/Format';
import ScheduleNotifications from './components/notifications/ScheduleNotifications';
import ScheduleSummary from './components/Summary';
import { IReportParametersShortVM } from './types/IReportParametersShortVM';
import DateTimeParser from '../../core/helpers/DateTimeParser';
import IReportParameter from '../Reports/types/IReportParameter';
import OnlineReportsApiClient from '../OnlineReports/OnlineReportsApiClient';

type SelectedFolderAndReport = {
    folder: IOnlineReportsFolder;
    report: IOnlineReport;
};

interface IState {
    creditorNumberType: number,
    isEditMode: boolean,
    isDataLoading: boolean,
    isSubmitting: boolean,
    currentStep: CreateReportScheduleSteps,
    currentStepIndex: number,
    scheduleId?: string,
    formData: {
        selectedReportFolderId: string,
        selectedReportFolder: string,
        selectedTypeReport: any,
        selectedTypeReportFolder: string
        folder?: IOnlineReportsFolder,
        report?: IOnlineReport,
        reportParameters?: IReportParametersShortVM[]
    },
    parameters: IReportParameter[],
    multiStepLabels: {
        submit: string,
        prev: string
    },
    scheduleParams: ISubscriptionDataVM,
    isScheduleParamsLoaded: boolean,
    scheduleData: IScheduleData,
    reportType?: SelectedFolderAndReport,
    folders: IOnlineReportsFolder[],
}

class ScheduleReport extends React.Component<IModuleProps & RouteComponentProps, IState> {
    private stepList: INumberDictionary<IStepListItem>;
    constructor(props: IModuleProps & RouteComponentProps) {
        super(props);
        const queryParams = RouteParamsService.getQueryParameters();
        const scheduleId = queryParams.scheduleId;

        this.stepList = this.getStepComponentsList();
        this.state = {
            creditorNumberType: 1,
            isEditMode: false,
            isDataLoading: true,
            isSubmitting: false,
            scheduleId: scheduleId as string | undefined,
            currentStep: scheduleId ? CreateReportScheduleSteps.Parameters : CreateReportScheduleSteps.Type,
            currentStepIndex: 0,
            formData: {
                selectedReportFolderId: '',
                selectedReportFolder: '',
                selectedTypeReport: {},
                selectedTypeReportFolder: '',
                reportParameters: []
            },
            multiStepLabels: {
                submit: 'View Report',
                prev: 'Previous Step'
            },
            scheduleParams: {} as ISubscriptionDataVM,
            isScheduleParamsLoaded: false,
            scheduleData: {} as IScheduleData,
            parameters: [],
            folders: []
        }
    }

    componentDidMount() {
        if (this.state.scheduleId) {
            this.fetchScheduleData(this.state.scheduleId);
        } else {
            OnlineReportsApiClient.getReports(this.props.module.id)
                .then((response) => {
                    this.setState({
                        isDataLoading: false,
                        folders: response.folders,
                        creditorNumberType: response.creditorNumberType
                    });
                });
        }
    }

    render() {
        return (
            <article className="l-module">
                <section className="l-module__section l-module__section--head">
                    <h1>
                        <i className="fas fa-chart-bar mr-2" />
                        {TranslationService.translateModule('Reports', ModuleNamesList.OnlineReports)}:
                        <strong className="l-module__title-highlighted">
                            {TranslationService.translateModule('TitleManageSchedule', ModuleNamesList.OnlineReports)}
                        </strong>
                    </h1>
                </section>

                <section className="l-module__section mt-3">
                    <div className="container container--force">
                        {this.state.isDataLoading ? (
                            <Loader opacity={0.5} />
                        ) : (
                            <div className="container container--force">
                                <MultiStepForm
                                    stepList={this.stepList}
                                    currentStep={this.state.currentStep}
                                    labels={this.state.multiStepLabels}
                                    onSubmit={this.onSubmit}
                                    onUpdateStep={this.updateCurrentStep}>
                                    <StepForm hideNextBtn={true}>
                                        <ReportType
                                            folders={this.state.folders}
                                            data={this.state.scheduleData}
                                            moduleId={this.props.module.id}
                                            updateData={this.updateReportTypeData} />
                                    </StepForm>

                                    <StepForm>
                                        <ReportParameters
                                            creditorType={this.state.creditorNumberType}
                                            isEditMode={this.state.isEditMode}
                                            moduleId={this.props.module.id}
                                            data={this.state.scheduleData}
                                            updateData={this.updateData}
                                            formData={this.state.formData}
                                        />
                                    </StepForm>

                                    <StepForm isLoaded={this.state.isScheduleParamsLoaded}>
                                        <ScheduleFrequency
                                            initData={this.state.scheduleParams}
                                            data={this.state.scheduleData}
                                            setScheduleOutputdata={this.setScheduleOutputdata} />
                                    </StepForm>

                                    <StepForm>
                                        <ScheduleFormats
                                            initData={this.state.scheduleParams.deliveryFormats}
                                            data={this.state.scheduleData}
                                            setScheduleOutputdata={this.setScheduleOutputdata} />
                                    </StepForm>

                                    <StepForm>
                                        <ScheduleNotifications
                                            initData={this.state.scheduleParams}
                                            data={this.state.scheduleData}
                                            setScheduleOutputdata={this.setScheduleOutputdata} />
                                    </StepForm>

                                    <StepForm>
                                        <ScheduleSummary
                                            notificationsData={this.state.scheduleParams.deliverySettings}
                                            scheduleData={this.state.scheduleData}
                                            formData={this.state.formData}
                                            isSubmitting={this.state.isSubmitting} />
                                    </StepForm>
                                </MultiStepForm>
                            </div>
                        )}
                    </div>
                </section>
            </article>
        )
    }

    fetchScheduleData = (scheduleId: string) => {
        ScheduleReportApiClient
            .getSchedule(this.props.module.id, scheduleId)
            .then((data) => {
                this.prepareScheduleData(data)
                OnlineReportsApiClient.getReports(this.props.module.id)
                    .then((response) => {
                        this.setState({
                            isDataLoading: false,
                            folders: response.folders,
                            creditorNumberType: response.creditorNumberType
                        });
                    });
            })
            .catch();
    };

    prepareScheduleData = (scheduleReportVm: IScheduleReportVm) => {
        const folder = scheduleReportVm.folders.find(f => {
            const folderReport = f.reports
                .find(r => {
                    return parseInt(r.id) === scheduleReportVm.scheduleData.reportId;
                });
            return folderReport !== undefined;
        });

        let report: IOnlineReport | undefined;
        if (folder) {
            report = folder.reports.find(r => {
                return parseInt(r.id) === scheduleReportVm.scheduleData.reportId;
            });
        }

        const schedulReportParameters = scheduleReportVm.scheduleData.reportParameters.map((param: any) => {
            if (!param.value) {
                return param;
            }

            param.value = param.value.split('~~');

            if (param.value.length === 1) {
                param.value = param.value[0];
            }

            return param;
        });

        this.setState({
            isEditMode: true,
            currentStep: CreateReportScheduleSteps.Parameters,
            isDataLoading: false,
            scheduleData: {
                ...scheduleReportVm.scheduleData,
                reportParameters: schedulReportParameters
            },
            formData: {
                ...this.state.formData,
                folder,
                report
            }
        });
    };

    updateReportTypeData = (data: {
        formData: {
            folder?: IOnlineReportsFolder,
            report?: IOnlineReport
        },
    }) => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...data.formData
            }
        });
    };

    updateData = (data: { reportParameters: IReportParametersShortVM[], parameters: IReportParameter[] }) => {
        this.setState({
            scheduleData: {
                ...this.state.scheduleData,
                reportParameters: data.reportParameters
            },
            parameters: data.parameters
        });
    };

    setScheduleOutputdata = (data: IScheduleData) => {
        this.setState({
            scheduleData: {
                ...this.state.scheduleData,
                ...data
            }
        });
    };

    onSubmit = () => {
        this.setState({ isSubmitting: true });
        const reportParameters = this.state.scheduleData.reportParameters.map((param: any) => {
            if (Array.isArray(param.value) && param.type === 'String') {
                param.value = param.value.join('~~');
            }

            if (param.type === 'DateTime') {
                if (!DateTimeParser.isValidFormat(param.value, 'YYYY-MM-DD')) {
                    param.value = DateTimeParser.toDateFormat(param.value, 'YYYY-MM-DD');
                }
            }

            return param;
        });

        const shceduleData: IScheduleData = {
            ...this.state.scheduleData,
            reportId: parseInt(this.state.formData.report ? this.state.formData.report.id : '0'),
            reportParameters
        }

        ScheduleReportApiClient.saveSchedule(this.props.module.id, shceduleData)
            .then(() => {
                this.setState({ isSubmitting: false });
                this.props.history.push('schedule-reports');
            })
            .catch(() => {
                this.setState({ isSubmitting: false });
            });
    };

    updateCurrentStep = async (currentStepValue: number) => {
        if (currentStepValue === 2) {
            await this.fetchSchedulesParamsData();
        }

        this.setState({
            currentStep: currentStepValue
        });
    };

    fetchSchedulesParamsData() {
        ScheduleReportApiClient.getReportSchedules(
            this.props.module.id,
            this.state.formData.report ? this.state.formData.report.id : '0'
        ).then((data) => {
            this.setState({
                scheduleParams: data,
                isScheduleParamsLoaded: true
            });
        }).catch((reject) => {
            console.error('Error', reject)
        });
    }

    private getStepComponentsList = (): INumberDictionary<IStepListItem> => {
        const stepComponents: INumberDictionary<IStepListItem> = {
            [CreateReportScheduleSteps.Type]: {
                iconCss: StepIconsCss.NewFile,
                text: TranslationService.translateModule('StepHeaderType', ModuleNamesList.OnlineReports),
            },
            [CreateReportScheduleSteps.Parameters]: {
                iconCss: StepIconsCss.Services,
                text: TranslationService.translateModule('StepHeaderParametersType', ModuleNamesList.OnlineReports),
            },
            [CreateReportScheduleSteps.Frequency]: {
                iconCss: StepIconsCss.Refresh,
                text: TranslationService.translateModule('StepHeaderScheduleFrequency', ModuleNamesList.OnlineReports),
            },
            [CreateReportScheduleSteps.Format]: {
                iconCss: StepIconsCss.Format,
                text: TranslationService.translateModule('StepHeaderScheduleFormat', ModuleNamesList.OnlineReports),
            },
            [CreateReportScheduleSteps.Notifications]: {
                iconCss: StepIconsCss.Notifications,
                text: TranslationService.translateModule('StepHeaderScheduleNotifications', ModuleNamesList.OnlineReports),
            },
            [CreateReportScheduleSteps.Summary]: {
                iconCss: StepIconsCss.Complete,
                text: TranslationService.translateModule('StepHeaderScheduleSummary', ModuleNamesList.OnlineReports),
            },
        };

        return stepComponents;
    }
}

export default withRouter(ScheduleReport);
