import { useState, useRef, useEffect } from 'react';
import styles from './Dropdown.module.scss';
import DropdownHeader from './components/DropdownHeader';
import DropdownList from './components/DropdownList';
import DropdownListItem from './components/DropdownListItem';
import { DropdownContext } from './context/DropdownContext';
import { DropdownType } from './types/DropdownType';
// @ts-ignore
import classNames from 'classnames';

type DropdownPropsType = {
    className?: string;
    isOpen?: boolean;
    isAlignToRight?: boolean;
    isAppendToBody?: boolean;
    toggle?: () => void;
};

const Dropdown = (props: DropdownType & DropdownPropsType) => {
    const [isShowing, setIsShowing] = useState<boolean>(props.isOpen || false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggle = () => {
        if (!isShowing) {
            if (dropdownRef.current) {
                const dropdownHeader = dropdownRef.current.querySelector<HTMLElement>('[data-ref="dropdownHeader"]');
                const dropdownList = dropdownRef.current.querySelector<HTMLElement>('[data-ref="dropdownList"]');
                const dropdownBounding = dropdownRef.current.getBoundingClientRect();

                if (dropdownList && dropdownHeader) {
                    dropdownList.style.top = '100%';
                    dropdownList.style.minWidth = `${dropdownHeader.offsetWidth}px`;
                    
                    if (props.isAlignToRight) {
                        dropdownList.style.right = `calc(100% - ${dropdownBounding.right}px)`;
                    }

                    if (props.isAppendToBody) {
                        dropdownList.style.top = `${dropdownBounding.bottom + window.pageYOffset}px`;
                        dropdownList.style.display = 'block';
                        dropdownList.setAttribute('data-ref', 'dropdownListCloned');

                        document.body.appendChild(dropdownList);
                    }
                }
            }
        } else if (props.isAppendToBody) {
            const dropdownListCloned = document.body.querySelector<HTMLElement>('[data-ref="dropdownListCloned"]');

            if (dropdownListCloned) {
                dropdownListCloned.style.display = 'none';
                dropdownListCloned.setAttribute('data-ref', 'dropdownList');

                if (dropdownRef.current) {
                    dropdownRef.current.appendChild(dropdownListCloned);
                }
            }
        }

        if (props.toggle) {
            props.toggle();
        } else {
            setIsShowing(!isShowing);
        }
    };

    const handleClick = (e: MouseEvent) => {
        if (dropdownRef.current) {
            const dropdownHeader = dropdownRef.current.querySelector<HTMLElement>('[data-ref="dropdownHeader"]');

            if (isShowing && dropdownHeader && !dropdownHeader.contains(e.target as Node)) {

                if (props.isAppendToBody) {
                    const dropdownListCloned = document.body.querySelector<HTMLElement>(
                        '[data-ref="dropdownListCloned"]'
                    );

                    if (dropdownListCloned && !dropdownListCloned.contains(e.target as Node)) {
                        dropdownListCloned.style.display = 'none';
                        dropdownListCloned.setAttribute('data-ref', 'dropdownList');

                        dropdownRef.current.appendChild(dropdownListCloned);
                        
                        setIsShowing(!isShowing);
                    }
                } else {
                    setIsShowing(!isShowing);
                }
            }
        }
    };

    useEffect(() => {
        if (typeof props.isOpen === 'boolean') {
            setIsShowing(props.isOpen);
        }
    }, [props.isOpen]);

    useEffect(() => {
        document.addEventListener('mouseup', handleClick);

        return () => {
            document.removeEventListener('mouseup', handleClick);
        };
    });

    return (
        <DropdownContext.Provider
            value={{
                isShowing,
                isAppendToBody: props.isAppendToBody || false,
                toggle,
            }}
        >
            <div
                ref={dropdownRef}
                className={classNames(
                    styles.dropdown,
                    !props.toggle && styles.noActions,
                    props.className && props.className
                )}
            >
                {props.children}
            </div>
        </DropdownContext.Provider>
    );
};

Dropdown.Header = DropdownHeader;
Dropdown.List = DropdownList;
Dropdown.ListItem = DropdownListItem;

export default Dropdown;
