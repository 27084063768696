import React, { useEffect, useState } from 'react';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import RouteParamsService from '../../../core/services/RouteParamsService';
import TranslationService from '../../../core/services/TranslationService';
import { IModuleProps } from '../../../core/types/IModuleProps';
import BalanceOfTotalReceivables from './components/BalanceOfTotalReceivablesTable';
import CreditorAndDebtorInfoTable from './components/CreditorAndDebtorInfoTable';
import DebtCollectionSummaryTable from './components/DebtCollectionSummaryTable';
import CreditorAccountSummaryService from './services/CreditorAccountSummaryService';
import { CreditorAccountSummaryType } from './types/CreditorAccountSummaryType';
import UnitCasePaymentDetailsTable from './components/UnitCasePaymentDetailsTable';
import Loader from '../../../core/components/Loading/Loader';
import { Card } from '../../../core/components/Card/Card';
import { CardContent } from '../../../core/components/Card/components/CardContent';
import './style.scss';
import ReactToPrint from 'react-to-print';
import { CreditorAccountSelector } from './types/CreditorAccountSelector';

const CreditorAccountSummaryComponent = (props: IModuleProps) => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [cas, setCreditorAccountSummary] = useState<CreditorAccountSummaryType>({
    accountNo: '',
    creditorExternalNo: '',
    creditorName: '',
    kontoNumber: '',
    creditorNo: '',
    debtorName: '',
    guarantorCasesCount: 0,
    balanceTotalReceivablesData: {
      balanceTotalReceivables: 0,
      ofWhichDebtCollectionCapital: 0,
      ofWhichDebtCollectionCost: 0,
      ofWhichOverdueCapital: 0,
    },
    unitCasePaymentDetails: [],
    debtCollectionSpecifications: [],
  } as CreditorAccountSummaryType);
  const queryParams = RouteParamsService.getQueryParameters();

  const componentRef = React.createRef() as any;
  const loadData = async () => {
    setIsDataLoading(true);

    try {
      const unitId = queryParams.unitId;
      const nameId = queryParams.nameId;
      const externalVoucherNo = queryParams.externalVoucherNo;
      const selectorType: CreditorAccountSelector =
        unitId !== undefined
          ? CreditorAccountSelector.UnitId
          : nameId !== undefined
          ? CreditorAccountSelector.NameId
          : CreditorAccountSelector.ExternalVoucherNo;
      const selectorValue =
        selectorType === CreditorAccountSelector.UnitId
          ? unitId
          : selectorType === CreditorAccountSelector.NameId
          ? nameId
          : externalVoucherNo;

      const result = await CreditorAccountSummaryService.getCreditorAccountSummary({
        accountNo: queryParams.accountNo as string,
        creditorNo: queryParams.creditorNo as string,
        debtorNo: queryParams.debtorNo as string,
        selectorValue: selectorValue as string,
        selector: selectorType,
        moduleInstanceId: props.module.id,
      });

      setCreditorAccountSummary(result);
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    const load = async () => {
      await loadData();
    };

    load();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container container--force">
      <div className="row">
        <div className="col-12">
          <ReactToPrint
            trigger={() => (
              <button className="btn btn--normal btn-color-2 btn-sm mt-3">
                {TranslationService.translate('Print')}
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
        <div className="col-12 pt-3 pb-3">
          <div ref={componentRef}>
            <Card>
              <CardContent>
                <>
                  {isDataLoading && <Loader opacity={0.7} />}
                  <CreditorAndDebtorInfoTable summary={cas} />

                  <BalanceOfTotalReceivables summary={cas} />

                  <DebtCollectionSummaryTable summary={cas} />

                  <div
                    className="c-creditoraccountsummary--small-text"
                    dangerouslySetInnerHTML={{
                      __html: TranslationService.translateModule(
                        'FooterMessage',
                        ModuleNamesList.CreditorAccountSummary
                      ),
                    }}
                  />
                  {cas.unitCasePaymentDetails && cas.unitCasePaymentDetails.length ? (
                    <article className="l-module cas">
                      <section className="l-module__section mt-3">
                        <article className="l-section">
                          <header className="l-section__header">
                            <h1>
                              {TranslationService.translateModule(
                                'UnitCasePaymentDetails',
                                ModuleNamesList.CreditorAccountSummary
                              )}
                            </h1>
                          </header>
                          <div className="l-section__body">
                            <UnitCasePaymentDetailsTable summary={cas} />
                          </div>
                        </article>
                      </section>
                    </article>
                  ) : null}
                </>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditorAccountSummaryComponent;
