import React from 'react';
import ButtonVariants from './types/ButtonVariants';
import ButtonSizes from './types/ButtonSizes';
import ButtonTypes from './types/ButtonTypes';
// @ts-ignore
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import styles from './ButtonCore.module.scss';

type ButtonPropsType = {
  id?: string;
  variant: ButtonVariants;
  size?: ButtonSizes;
  type?: ButtonTypes;
  className?: string;
  styles?: any;
  hoverStyles?: any;
  label?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  dataCy?: string;
};

const ButtonCore = (props: ButtonPropsType) => {
  return (
    <ButtonStyled
      data-cy={props.dataCy}
      id={props.id}
      type={props.type}
      styles={props.styles}
      hoverStyles={props.hoverStyles}
      className={classNames(
        styles.wrapper,
        styles[props.variant],
        props.size && styles[props.size],
        props.disabled && styles.disabled,
        props.className && `${props.className}`
      )}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children ? props.children : props.label}
    </ButtonStyled>
  );
};

const ButtonStyled = styled.button<{ styles: string; hoverStyles: any }>`
  ${(props) => css`
    ${props.styles}
    &:hover {
      ${props.hoverStyles}
    }
  `}
`;

ButtonCore.Variants = ButtonVariants;
ButtonCore.Sizes = ButtonSizes;
ButtonCore.Types = ButtonTypes;

export default ButtonCore;

export { ButtonVariants, ButtonSizes, ButtonTypes };
