import React from 'react';
import { connect } from 'react-redux';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { ICreditor } from '../../../core/types/ICreditor';
import { IOnlineReport } from '../../Reports/types/IOnlineReport';
import { IOnlineReportsFolder } from '../../Reports/types/IOnlineReportsFolder';
import { ParameterNames } from '../../Reports/types/ParameterNames';
import { IDeliverySettingSetVM } from '../types/IDeliverySettingSetVM';
import { IScheduleMode } from '../types/IScheduleMode';
import TranslationService from './../../../core/services/TranslationService';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';

interface IFormats {
  id: number;
  description: string;
  name: string;
}

interface IState {
  creditors: ICreditor[];
  dateReport: any[];
  frequency: any;
  formats: any[];
  notifications: any;
  isSFTP: boolean;
  sftpData: {
    serverName: string;
    userName: string;
    password: string;
  };
  initData: {
    formats: IFormats[];
  };
}

interface IProps {
  isSubmitting: boolean;
  notificationsData: IDeliverySettingSetVM[];
  creditors: ICreditor[];
  scheduleData: any;
  formData: {
    folder?: IOnlineReportsFolder;
    report?: IOnlineReport;
  };
  goToPreviousStep?: () => void;
  goToNextStep?: (stepData: {}, lastStep: boolean) => void;
}

class ScheduleSummary extends React.PureComponent<IProps, IState> {
  state: IState = {
    creditors: [],
    dateReport: [],
    frequency: {
      name: '',
      label: '',
    },
    formats: [],
    notifications: {},
    isSFTP: false,
    sftpData: {
      serverName: '',
      userName: '',
      password: '',
    },
    initData: {
      formats: [
        {
          id: 1,
          description: 'HTML',
          name: 'html',
        },
        {
          id: 2,
          description: 'PDF',
          name: 'pdf',
        },
        {
          id: 3,
          description: 'EXCEL',
          name: 'excel',
        },
      ],
    },
  };

  componentDidMount() {
    this.prepareData();
  }

  render() {
    return (
      <>
        <div className="c-wizar-summary">
          <div className="c-wizar-summary__item"></div>
        </div>

        <div className="c-creator__body">
          <div className="c-creator__overview">
            <div className="c-creator__overview-tile">
              {this.props.formData.folder ? this.props.formData.folder.name : ''}
            </div>

            <div className="c-creator__overview-desc">
              <p>
                <b>{this.props.formData.report ? this.props.formData.report.name : ''}</b>
              </p>

              <p>
                <span className="pr-10">
                  <>
                    {TranslationService.translateModule('From', ModuleNamesList.ScheduleReports)}:{' '}
                    <b>{this.state.dateReport[0]}</b>
                  </>
                </span>
                {this.state.dateReport[1] && (
                  <span className="pr-10">
                    <>
                      {TranslationService.translateModule('To', ModuleNamesList.ScheduleReports)}:{' '}
                      <b>{this.state.dateReport[1]}</b>
                    </>
                  </span>
                )}
              </p>

              <p dangerouslySetInnerHTML={{ __html: `${this.state.frequency.name}, ${this.state.frequency.label}` }} />
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <div className="c-creator__box">
                <p className="c-creator__box-title">
                  {TranslationService.translateModule('Creditors', ModuleNamesList.ScheduleReports)}
                </p>

                <ul className="c-list c-list--2">
                  {this.state.creditors.map((creditor: any, index: number) => (
                    <li className="c-list__item" key={index}>
                      <b>{creditor.domainId}</b> {creditor.organizationName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-2">
              <div className="c-creator__box">
                <p className="c-creator__box-title">
                  {TranslationService.translateModule('Format', ModuleNamesList.ScheduleReports)}
                </p>

                <ul className="c-list c-list--2">
                  {this.state.formats.map((format: IFormats, index: number) => (
                    <li className="c-list__item" key={index}>
                      <b>{format}</b>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-6">
              <div className="c-creator__box">
                <p className="c-creator__box-title">
                  {TranslationService.translateModule('ScheduleNotification', 'ScheduleReport')}
                </p>
                {this.state.notifications.notificationsType && (
                  <ul className="c-list c-list--2">
                    {this.state.notifications.notificationsType.map((notification: any, index: number) => (
                      <li className="c-list__item" key={index}>
                        <b>{notification}</b>
                      </li>
                    ))}
                  </ul>
                )}

                {this.state.isSFTP && (
                  <ul className="c-list c-list--3">
                    <li>
                      {TranslationService.translateModule('ScheduleNotificationsSftpServer', 'ScheduleReport')}:
                      <span className="c-list__item--decor">{this.state.sftpData.serverName}</span>
                    </li>
                    <li>
                      {TranslationService.translateModule('ScheduleNotificationsSftpUser', 'ScheduleReport')}:
                      <span className="c-list__item--decor">{this.state.sftpData.userName}</span>
                    </li>
                    <li>
                      {TranslationService.translateModule('ScheduleNotificationsSftpPassword', 'ScheduleReport')}:
                      <span className="c-list__item--decor">{this.state.sftpData.password}</span>
                    </li>
                  </ul>
                )}

                {this.state.notifications.copyNotificationTo && (
                  <ul className="c-list c-list--3">
                    <li className="c-list__item">
                      {TranslationService.translateModule('SendNotificationTo', ModuleNamesList.ScheduleReports)}

                      <span className="c-list__item--decor">
                        {this.state.notifications.copyNotificationTo.map((notification: any, index: number) => (
                          <span key={index}>{notification},</span>
                        ))}
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="c-creator__footer c-creator__footer--btn-action">
          <Button
            type="button"
            label={TranslationService.translate('Back')}
            variant={BUTTON_VARIANTS.SECONDARY}
            size={BUTTON_SIZE.MD}
            onClick={this.props.goToPreviousStep}
          />

          <Button
            label={TranslationService.translate('Save')}
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTON_SIZE.MD}
            onClick={this.submitFormHandler}
            disabled={this.props.isSubmitting}
            showLoader={this.props.isSubmitting}
          />
        </div>
      </>
    );
  }

  prepareData() {
    this.prepareReportParams();
    this.prepareFrequencyData();
    this.prepareFormatData();
    this.prepareNotificationData();
  }

  prepareReportParams = () => {
    const reportParameters = this.props.scheduleData.reportParameters;
    let dateFrom = '';
    let dateTo = '';

    for (let i = 0; i < reportParameters.length; i++) {
      const param = reportParameters[i];
      if (param.name === ParameterNames.CreditorNo) {
        this.prepeareCreditor(param.value);
      }
      if (param.type === 'DateTime') {
        if (param.name.indexOf('_From') > -1) {
          dateFrom = param.value;
        }

        if (param.name.indexOf('_To') > -1) {
          dateTo = param.value;
        }
      }
    }

    this.setState({
      dateReport: [dateFrom, dateTo],
    });
  };

  prepareFrequencyData = () => {
    const scheduleData = this.props.scheduleData.parameters;
    let frequency = {};

    scheduleData.startTimeMinutes = `0${scheduleData.startTimeMinutes}`.slice(-2);

    switch (parseInt(scheduleData.mode)) {
      case IScheduleMode.Daily:
        frequency = {
          name: `<b>${TranslationService.translateModule('Daily', ModuleNamesList.ScheduleReports)}</b>`,
          label: `
                        ${TranslationService.translateModule('RepeatEveryDayAt', ModuleNamesList.ScheduleReports)} <b>${
            scheduleData.startTimeHours
          }:${scheduleData.startTimeMinutes}</b>, 
                        ${TranslationService.translate('From').toLowerCase()} <b>${DateTimeParser.toLocaleDateString(
            scheduleData.startTime
          )}</b> ${
            scheduleData.stopTime
              ? `${TranslationService.translate('To').toLowerCase()} <b>${DateTimeParser.toLocaleDateString(
                  scheduleData.stopTime
                )}</b>`
              : ''
          }`,
        };
        break;
      case IScheduleMode.Weekly:
        const weekDays = [
          { id: 1, name: TranslationService.translate('Mon') },
          { id: 2, name: TranslationService.translate('Tue') },
          { id: 3, name: TranslationService.translate('Wed') },
          { id: 4, name: TranslationService.translate('Thu') },
          { id: 5, name: TranslationService.translate('Fri') },
          { id: 6, name: TranslationService.translate('Sat') },
          { id: 7, name: TranslationService.translate('Sun') },
        ];

        const days = weekDays
          .filter(({ id }) => {
            return scheduleData.weekDayNumbers.indexOf(id) > -1;
          })
          .map(({ name }) => {
            return name;
          })
          .join(', ');

        frequency = {
          name: `<b>${TranslationService.translateModule('Weekly', ModuleNamesList.ScheduleReports)}</b>`,
          label: `
                        ${TranslationService.translateModule(
                          'RepeatEvery',
                          ModuleNamesList.ScheduleReports
                        ).toLowerCase()} <b>${days}</b>, 
                        ${TranslationService.translate('At').toLowerCase()} <b>${scheduleData.startTimeHours}:${
            scheduleData.startTimeMinutes
          }</b>, 
                        ${TranslationService.translate('From').toLowerCase()} <b>${DateTimeParser.toLocaleDateString(
            scheduleData.startTime
          )}</b> ${
            scheduleData.stopTime
              ? `${TranslationService.translate('To').toLowerCase()} <b>${DateTimeParser.toLocaleDateString(
                  scheduleData.stopTime
                )}</b>`
              : ''
          }`,
        };
        break;
      case IScheduleMode.Monthly:
        frequency = {
          name: `<b>${TranslationService.translateModule('Monthly', ModuleNamesList.ScheduleReports)}</b>`,
          label: `
                        ${TranslationService.translateModule(
                          'RepeatEvery',
                          ModuleNamesList.ScheduleReports
                        ).toLowerCase()} <b>${
            scheduleData.calendarDayNumbers[0]
          }</b> ${TranslationService.translateModule(
            'DayOfTheMonthAt',
            ModuleNamesList.ScheduleReports
          ).toLowerCase()} <b>${scheduleData.startTimeHours}:${scheduleData.startTimeMinutes}</b>, 
                        ${TranslationService.translate('From').toLowerCase()} <b>${DateTimeParser.toLocaleDateString(
            scheduleData.startTime
          )}</b> ${
            scheduleData.stopTime
              ? `${TranslationService.translate('To').toLowerCase()} <b>${DateTimeParser.toLocaleDateString(
                  scheduleData.stopTime
                )}</b>`
              : ''
          }`,
        };
        break;
      case IScheduleMode.Once:
        frequency = {
          name: `<b>${TranslationService.translateModule('Once', ModuleNamesList.ScheduleReports)}</b>`,
          label: `
                        ${TranslationService.translateModule('RepeatOnceAt', ModuleNamesList.ScheduleReports)} <b>${
            scheduleData.startTimeHours
          }:${scheduleData.startTimeMinutes}</b>, 
                        ${TranslationService.translate('On').toLowerCase()} <b>${DateTimeParser.toLocaleDateString(
            scheduleData.startTime
          )}</b>`,
        };
        break;
    }

    this.setState({ frequency });
  };

  prepareNotificationData = () => {
    const stfpInfo = {
      name: 'SFTP',
      index: 0,
      isAvailable: false,
    };

    const notificationsType = this.props.notificationsData
      .filter(({ deliveryType: { id } }: any) => {
        return this.props.scheduleData.deliverySettings.indexOf(id) > -1;
      })
      .map(({ deliveryType: { name, description } }: any, index: number) => {
        if (name === 'SFTP') {
          stfpInfo.index = index - 1;
          stfpInfo.isAvailable = true;
        }

        return description;
      });

    let copyNotificationTo = this.props.scheduleData.copyNotificationTo;

    if (copyNotificationTo && copyNotificationTo.length > 0) {
      copyNotificationTo = copyNotificationTo.split(';');
    }

    if (stfpInfo.isAvailable) {
      notificationsType.push(notificationsType.splice(stfpInfo.index, 1)[0]);
      this.prepareSFTPDate();
    }

    this.setState({
      notifications: {
        notificationsType,
        copyNotificationTo,
      },
    });
  };

  prepareSFTPDate = () => {
    this.setState({
      isSFTP: true,
      sftpData: {
        serverName: this.props.scheduleData.sftpHost,
        userName: this.props.scheduleData.sftpUser,
        password: this.props.scheduleData.sftpPassword,
      },
    });
  };

  prepareFormatData = () => {
    const formats = this.state.initData.formats
      .filter((format: IFormats) => {
        return parseFloat(this.props.scheduleData.deliveryFormat) === format.id;
      })
      .map((format: IFormats) => {
        return format.description;
      });

    this.setState({ formats });
  };

  prepeareCreditor = (creditors: any) => {
    const validCreditors = this.props.creditors.filter((creditor: any) => {
      return creditors.indexOf(creditor.domainId) > -1;
    });

    this.setState({ creditors: validCreditors });
  };

  submitFormHandler = () => {
    if (this.props.goToNextStep) {
      this.props.goToNextStep({}, true);
    }
  };
}

const mapStateToProps = (state: any) => {
  return {
    creditors: state.creditors.filter((c: any) => c.isAuthorized),
  };
};

export default connect(mapStateToProps)(ScheduleSummary);
