import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { ILanguage } from '../../core/types/ILanguage';
import { IStore } from '../../reducers/IStore';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../core/components/Forms/FormGroup';
import SimpleInput from '../../core/components/Forms/SimpleInput';
import { ErrorMessage } from 'formik';
import { Card } from '../../core/components/Card/Card';
import { CardContent } from '../../core/components/Card/components/CardContent';
import { CardAside, CardAsideVariants } from '../../core/components/Card/components/CardAside';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import ErrorResponseHandler from '../../core/services/ErrorResponseHandler';
import MessageTemplatesManagementApi from './api/MessageTemplatesManagementApi';
import { SimpleSelect } from '../../core/components/Forms/SimpleSelect';
import { Typography, TypographyVariants } from '../../core/components/Typography/Typography';
import RichTextEditor from 'react-rte';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';
import TranslationService from '../../core/services/TranslationService';
import useEffectAsync from '../../core/hooks/useEffectAsync';
import Loader from '../../core/components/Loading/Loader';
import classNames from 'classnames';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import './style.scss';
import InfoMessageService from '../../core/services/InfoMessageService';
import { CardHeader } from '../../core/components/Card/components/CardHeader';
import { MessageTemplatesModel } from './api/MessageTemplatesModel';
import { MessageTemplateModel } from './types/MessageTemplateModel';

const MessageTemplatesManagement = (
  props: {
    languages: ILanguage[];
    currentCultureCode: string;
  } & IExtendedModuleProps
) => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [currentTemplateCode, setCurrentTemplateCode] = useState<string>();
  const [currentTemplate, setCurrentTemplate] = useState<any>();
  const [filter, setFilter] = useState<string>('');
  const [templateCodeList, setTemplateCodeList] = useState<string[]>([]);
  const [templateMessage, setTemplateMessage] = useState<MessageTemplatesModel>(
    {} as MessageTemplatesModel
  );
  const [hasAccess, setHasAccess] = useState<boolean>(true);
  const [content, setContent] = useState(RichTextEditor.createValueFromString('', 'html'));
  const [subject, setSubject] = useState<string>('');
  const [languages, setLanguages] = useState<any[]>([]);
  const [cultureCode, setCultureCode] = useState<string>(props.currentCultureCode);

  const toolbarConfig: any = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'c-btn c-btn--1 c-btn--md' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [],
  };

  const renderList = (value?: string) => {
    return (
      <ul className="c-list c-list--primary">
        {value
          ? templateCodeList
              .filter((templateCode: string) => (templateCode.includes(value) ? true : false))
              .map((templateCode: string, index: number) => (
                <li
                  key={index}
                  className={classNames(
                    'c-list__item c-list__item--pointer',
                    currentTemplateCode === templateCode && 'c-list__item--active'
                  )}
                  onClick={() => setCurrentTemplateCode(templateCode)}
                >
                  {templateCode}
                </li>
              ))
          : templateCodeList.map((templateCode: string, index: number) => (
              <li
                key={index}
                className={classNames(
                  'c-list__item c-list__item--pointer',
                  currentTemplateCode === templateCode && 'c-list__item--active'
                )}
                onClick={() => setCurrentTemplateCode(templateCode)}
              >
                {templateCode}
              </li>
            ))}
      </ul>
    );
  };

  const renderTags = () => {
    let tags: any[] = [];

    if (templateMessage && templateMessage.fields) {
      tags = Object.keys(templateMessage.fields).map((key) => {
        return {
          value: key,
          name: TranslationService.translateModule(
            templateMessage.fields[key],
            ModuleNamesList.MessageTemplatesManagement
          ),
        };
      });
    }

    return (
      <ul className="c-template-tag-list">
        {tags.map((tag, index: number) => {
          return (
            <li key={index}>
              {`@Model.${tag.value}`}
              <span>{tag.name}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const handleTemplateSave = async () => {
    setIsDataLoading(true);

    let templateData: MessageTemplateModel = {} as MessageTemplateModel;

    if (currentTemplate) {
      templateData = {
        messageTemplateId: currentTemplate.messageTemplateId,
        code: currentTemplate.code,
        description: currentTemplate.description,
        subject: subject,
        htmlText: content.toString('html'),
        plainText: currentTemplate.plainText,
        culture: cultureCode,
      };
    }

    const messageTemplates = templateMessage.messageTemplates.map((messatgeTemplate) =>
      messatgeTemplate.messageTemplateId === currentTemplate.messageTemplateId
        ? templateData
        : messatgeTemplate
    );

    try {
      const saveResult = await MessageTemplatesManagementApi.saveMessageTemplate(templateData);

      if (saveResult.status === 1) {
        InfoMessageService.success(
          TranslationService.translateModule(
            'SaveTemplateSuccess',
            ModuleNamesList.MessageTemplatesManagement
          )
        );

        setTemplateMessage({
          ...templateMessage,
          messageTemplates,
        });
      } else {
        InfoMessageService.error(
          TranslationService.translateModule(
            'SaveTemplateError',
            ModuleNamesList.MessageTemplatesManagement
          )
        );
      }
    } finally {
      setIsDataLoading(false);
    }
  };

  const getTemplateCodeList = async () => {
    setIsDataLoading(true);

    try {
      const codes = await MessageTemplatesManagementApi.getMessageTemplatesCodes();

      setTemplateCodeList(codes);
    } catch (error: any) {
      const hasAccess = ErrorResponseHandler.hasAccess(error);

      setHasAccess(hasAccess);
    }
  };

  const getLanguageOptions = () => {
    const languages = props.languages.map((language) => {
      return {
        key: language.code,
        value: TranslationService.translate(`${language.name}`),
      };
    });

    setLanguages(languages);
  };

  const getTemplateMessage = async () => {
    if (currentTemplateCode) {
      setIsDataLoading(true);

      try {
        const messageTemplate = await MessageTemplatesManagementApi.getMessageTemplatesByCode(
          currentTemplateCode
        );

        setTemplateMessage(messageTemplate);
      } catch (error: any) {
        const hasAccess = ErrorResponseHandler.hasAccess(error);

        setHasAccess(hasAccess);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(templateMessage).length && cultureCode) {
      const template = templateMessage.messageTemplates.find((template) => {
        return template.culture === cultureCode ? true : false;
      });

      if (template) {
        setCurrentTemplate(template);
        setSubject(template.subject);
        setContent(RichTextEditor.createValueFromString(template.htmlText, 'html'));
        setIsDataLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [templateMessage, cultureCode]);

  useEffect(() => {
    getTemplateMessage();
    // eslint-disable-next-line
  }, [currentTemplateCode]);

  useEffect(() => {
    if (templateCodeList.length) {
      setCurrentTemplateCode(templateCodeList[0]);
    }
    // eslint-disable-next-line
  }, [templateCodeList]);

  useEffectAsync(async () => {
    await getTemplateCodeList();
    getLanguageOptions();
    // eslint-disable-next-line
  }, []);

  return !hasAccess ? (
    <NoPermissionsComponent />
  ) : (
    <>
      {isDataLoading && <Loader opacity={0.4} />}

      <Card class="mt-3 mb-3" container={true}>
        <>
          <CardHeader>
            <h1 className="c-heading">
              {TranslationService.translateModule(
                'PageTitle',
                ModuleNamesList.MessageTemplatesManagement
              )}
            </h1>
          </CardHeader>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <CardAside variant={CardAsideVariants.Secondary}>
                <>
                  <div className="p-4">
                    <Typography variant={TypographyVariants.H3} uppercase={true}>
                      {TranslationService.translateModule(
                        'TemplateName',
                        ModuleNamesList.MessageTemplatesManagement
                      )}
                    </Typography>

                    <div className="mt-3">
                      <FormGroup
                        variant={FORM_GROUP_VARIANTS.INLINE}
                        className="mb-0"
                        name="invoiceNoTo"
                        render={(name) => (
                          <>
                            <SimpleInput
                              id="filteredByValue"
                              placeholder="Search template"
                              value={filter}
                              onChange={(value) => setFilter(value)}
                            />

                            <ErrorMessage
                              name={name}
                              component="div"
                              className="error error-message"
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>

                  {renderList(filter)}
                </>
              </CardAside>
            </div>

            <div className="col-sm-12 col-md-8">
              <CardContent>
                <>
                  {currentTemplate && (
                    <>
                      <Typography variant={TypographyVariants.H1}>
                        {currentTemplate.code}
                      </Typography>

                      <Typography variant={TypographyVariants.Paragraph}>
                        {currentTemplate.description}
                      </Typography>
                    </>
                  )}

                  <div className="mt-4">
                    <FormGroup
                      variant={FORM_GROUP_VARIANTS.BLOCK}
                      name="language"
                      label={TranslationService.translateModule(
                        'Language',
                        ModuleNamesList.MessageTemplatesManagement
                      )}
                      bolded={true}
                      render={(name) => (
                        <>
                          <SimpleSelect
                            id={name}
                            options={languages}
                            value={cultureCode}
                            onChangeHandle={(language) => {
                              setIsDataLoading(true);
                              setCultureCode(language.key);
                              setIsDataLoading(false);
                            }}
                          />

                          <ErrorMessage
                            name={name}
                            component="div"
                            className="error error-message"
                          />
                        </>
                      )}
                    />
                  </div>

                  <div className="mt-4">
                    <FormGroup
                      variant={FORM_GROUP_VARIANTS.BLOCK}
                      name="subject"
                      label={TranslationService.translateModule(
                        'SubjectTemplate',
                        ModuleNamesList.MessageTemplatesManagement
                      )}
                      bolded={true}
                      render={(name) => (
                        <>
                          <SimpleInput id="subject" value={subject} onChange={setSubject} />

                          <ErrorMessage
                            name={name}
                            component="div"
                            className="error error-message"
                          />
                        </>
                      )}
                    />
                  </div>

                  <div className="mt-4">
                    <FormGroup
                      variant={FORM_GROUP_VARIANTS.BLOCK}
                      bolded={true}
                      name="subject"
                      label={TranslationService.translateModule(
                        'ContentTemplate',
                        ModuleNamesList.MessageTemplatesManagement
                      )}
                      render={() => (
                        <div className="mt-2">
                          <Typography
                            variant={TypographyVariants.Span}
                            uppercase={true}
                            class="mb-2"
                          >
                            {TranslationService.translateModule(
                              'AvailableTagnames',
                              ModuleNamesList.MessageTemplatesManagement
                            )}
                          </Typography>

                          {renderTags()}

                          <RichTextEditor
                            toolbarConfig={toolbarConfig}
                            value={content}
                            onChange={(value) => setContent(value)}
                          />
                        </div>
                      )}
                    />
                  </div>

                  <div className="mt-3">
                    <Button
                      variant={BUTTON_VARIANTS.PRIMARY}
                      size={BUTTON_SIZE.MD}
                      type="submit"
                      label={TranslationService.translate('Save')}
                      onClick={handleTemplateSave}
                    />
                  </div>
                </>
              </CardContent>
            </div>
          </div>
        </>
      </Card>
    </>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    currentCultureCode: state.currentCultureCode,
    languages: state.languages,
  };
};

export default connect(mapStateToProps)(MessageTemplatesManagement);
