import React from 'react';
import styles from '../Dropdown.module.scss';
import { useDropdownContext } from '../context/DropdownContext';
import { DropdownType } from '../types/DropdownType';
// @ts-ignore
import classNames from 'classnames';

type PropsType = {
    classNames?: string,
}

const DropdownHeader = (props: DropdownType & PropsType) => {
    const { toggle } = useDropdownContext()

    return (
        <div
            data-ref="dropdownHeader"
            className={classNames(
                styles.dropdownHeader,
                props.classNames
            )}
            onClick={toggle}
        >
            {props.children}
        </div>
    )
}

export default React.memo(DropdownHeader);