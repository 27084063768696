import { Form, Formik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';
import TranslationService from '../../../core/services/TranslationService';
import { IInvoiceDetails, emptyInvoice } from '../types/IInvoiceDetails';
import { IInvoicesSetup } from '../types/IInvoicesSetup';
import FormGroupField from '../../../core/components/Forms/FormGroupField';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import { IDictionary } from '../../../core/types/IDictionary';
import { ICurrency } from '../types/ICurrency';
import FormGroupDatePicker from '../../../core/components/Forms/FormGroupDatePicker';
import { IDebtorDetails } from '../types/IDebtorDetails';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
// import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';
import { IClaimInfo } from './IClaimInfo';
import { CaseType } from '../types/CaseType';
import { ISelectedValue } from '../../../core/components/Forms/types/ISelectedValue';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { IInitialSettings } from '../types/IInitialSettings';

interface IState {
  invoiceDetails: IInvoiceDetails;
  isInterestFieldEnabled: boolean;
}

interface IProps {
  invoicesSetup: IInvoicesSetup;
  debtorDetails: IDebtorDetails;
  instanceSettings: IInitialSettings;
  claimInfo: IClaimInfo;
  addNewInvoice: (invoice: IInvoiceDetails) => void;
}

class NewInvoiceForm extends React.PureComponent<IProps, IState> {
  public state: IState = {
    invoiceDetails: {
      ...emptyInvoice,
      interest: this.props.invoicesSetup.standardInvoiceRate,
      currency: this.props.invoicesSetup.defaultCurrency,
      interestTypeId: this.props.instanceSettings.availableInterestRateTypes.find((x) => x.isStandard)!.interestId,
    },
    isInterestFieldEnabled: false,
  };

  private validationMessages = {
    invoiceNumberMaxLength: TranslationService.translateFormat('ValidationMessageMaximiumFieldLengthIs', '64'),
    invoiceDescriptionMaxLength: TranslationService.translateFormat('ValidationMessageMaximiumFieldLengthIs', '30'),
    required: TranslationService.translate('ValidationMessageRequiredField'),
    incorrectNumberFormat: TranslationService.translate('ValidationMessageIncorrectNumberFormat'),
    lessThanZeroValue: TranslationService.translate('ValidationMessageLessThanZeroValue'),
    valueIsToBig: TranslationService.translate('ValidationMessageValueIsToBig'),
    dueDateAfterToday: TranslationService.translate('ValidationMessageDueDateAfterToday'),
    dueDateBeforeAddDate: TranslationService.translate('ValidationMessageDueDateBeforeAddDate'),
  };

  private validationSchema = yup.object().shape({
    number: yup
      .string()
      .required(this.validationMessages.required)
      .max(64, this.validationMessages.invoiceNumberMaxLength),
    text: yup
      .string()
      .required(this.validationMessages.required)
      .max(30, this.validationMessages.invoiceDescriptionMaxLength),
    amount: yup
      .number()
      .max(999999999, this.validationMessages.valueIsToBig)
      .typeError(this.validationMessages.incorrectNumberFormat)
      .required(this.validationMessages.required),
    reminderFee: yup
      .number()
      .max(999999999, this.validationMessages.valueIsToBig)
      .typeError(this.validationMessages.incorrectNumberFormat),
    interest: yup
      .number()
      .min(0, this.validationMessages.lessThanZeroValue)
      .typeError(this.validationMessages.incorrectNumberFormat)
      .required(this.validationMessages.required),
    dateAdd: yup.date().required(this.validationMessages.required),
    dateDue: yup
      .date()
      .required(this.validationMessages.required)
      .max(new Date(), this.validationMessages.dueDateAfterToday)
      .test('dueDate-before-dateAdd', this.validationMessages.dueDateBeforeAddDate, function (value: any) {
        const { dateAdd } = this.parent;
        if (dateAdd) {
          return value >= dateAdd;
        }

        return true;
      }),
    dateCollectionNotice: yup.lazy(() => {
      return this.props.claimInfo.caseType === CaseType.CollectionCase
        ? yup.date().required(this.validationMessages.required)
        : yup.date().notRequired();
    }),
    dateDueNotice: yup.lazy(() => {
      return this.props.claimInfo.caseType === CaseType.CollectionCase
        ? yup.date().required(this.validationMessages.required)
        : yup.date().notRequired();
    }),
    interestTypeId: yup.number().required(this.validationMessages.required).min(0, this.validationMessages.required),
  });

  public render() {
    const interestTypeOptions: IDictionary<string> = {};
    this.props.instanceSettings.availableInterestRateTypes.forEach((item) => {
      interestTypeOptions[item.interestId] = item.interestName;
    });
    return (
      <Formik
        initialValues={{ ...this.state.invoiceDetails }}
        onSubmit={(values: IInvoiceDetails, { setSubmitting, setErrors, resetForm }) => {
          const interest = this.props.instanceSettings.availableInterestRateTypes.find(
            (interest) => interest.interestId === values.interestTypeId
          );

          this.props.addNewInvoice({
            ...values,
            interest: interest && interest.isStandard ? interest.interestName : values.interest,
          });

          resetForm();
        }}
        validationSchema={this.validationSchema}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => {
          return (
            <Form>
              <div className="row">
                <div className="col-12 col-md-8">
                  <div className="c-control row">
                    <label className="col-sm-4 col-form-label" htmlFor="amount">
                      {TranslationService.translateModule('CaseInfoInvoiceDebtor', ModuleNamesList.CreateNewCase)}
                      <span className="c-control__required-sign"></span>
                    </label>

                    <div className="col-12 col-sm-8">
                      <SimpleInput
                        id="debtor-details"
                        className="form-control"
                        value={this.getDebtorDetails()}
                        disabled={true}
                        onChange={() => {}}
                      />
                    </div>
                  </div>

                  <FormGroupField
                    errors={errors}
                    fieldName="number"
                    handleChange={handleChange}
                    label={TranslationService.translateModule('CaseInfoInvoiceNumber', ModuleNamesList.CreateNewCase)}
                    handleBlur={handleBlur}
                    touched={touched}
                    labelClass="col-sm-4"
                    inputContainerClass="col-12 col-sm-8"
                    required={true}
                    value={values.number}
                  />

                  <FormGroupField
                    errors={errors}
                    fieldName="text"
                    handleChange={handleChange}
                    label={TranslationService.translateModule(
                      'CaseInfoInvoiceDescription',
                      ModuleNamesList.CreateNewCase
                    )}
                    handleBlur={handleBlur}
                    touched={touched}
                    labelClass="col-sm-4"
                    inputContainerClass="col-12 col-sm-8"
                    required={true}
                    value={values.text}
                  />

                  <div className="c-control row">
                    <label className="col-sm-4 col-form-label" htmlFor="amount">
                      {TranslationService.translateModule('CaseInfoAmount', ModuleNamesList.CreateNewCase)}
                      <span className="c-control__required-sign"></span>
                    </label>

                    <div className="col-12 col-sm-8">
                      <div className="row">
                        <FormGroupField
                          errors={errors}
                          fieldName="amount"
                          handleBlur={handleBlur}
                          hideFormGroupDiv={true}
                          handleChange={handleChange}
                          hideLabel={true}
                          inputContainerClass="col-8"
                          touched={touched}
                          value={values.amount}
                        />

                        <div className="col-4 pl-0">
                          <SimpleSelect
                            id="available-currencies"
                            isNotStandard={true}
                            options={this.availableCurrencies()}
                            value={values.currency}
                            onChangeHandle={(selectedValue: ISelectedValue) => {
                              handleChange({
                                target: {
                                  value: selectedValue.key,
                                  name: 'currency',
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.props.invoicesSetup.displayInvoiceReminderFee && (
                    <FormGroupField
                      errors={errors}
                      fieldName="reminderFee"
                      handleChange={handleChange}
                      label={TranslationService.translateModule('CaseInfoReminderFee', ModuleNamesList.CreateNewCase)}
                      handleBlur={handleBlur}
                      touched={touched}
                      labelClass="col-sm-4"
                      inputContainerClass="col-12 col-sm-8"
                      required={false}
                      value={values.reminderFee}
                    />
                  )}

                  <div className="form-group row">
                    <div className="col-12">
                      <div className="row">
                        <label className="col-sm-4 col-form-label" htmlFor="interest">
                          {TranslationService.translateModule(
                            'CaseInfoInvoiceInterestRate',
                            ModuleNamesList.CreateNewCase
                          )}
                        </label>
                        <div className="col-12 col-sm-8">
                          <div className="row">
                            <div className="col-8">
                              <SimpleSelect
                                id="interest-options"
                                isNotStandard={true}
                                value={values.interestTypeId.toString()}
                                options={interestTypeOptions}
                                onChangeHandle={(selected) => {
                                  setFieldValue('interestTypeId', parseInt(selected.key));
                                  setFieldValue('interest', this.props.invoicesSetup.standardInvoiceRate);
                                  this.setState({
                                    isInterestFieldEnabled:
                                      !this.props.instanceSettings.availableInterestRateTypes.find(
                                        (x) => x.interestId.toString() === selected.key
                                      )!.isStandard,
                                  });
                                }}
                              />
                              {errors.interestTypeId && touched.interestTypeId && (
                                <span className="error error-message">{errors.interestTypeId}</span>
                              )}
                            </div>
                            <FormGroupField
                              errors={errors}
                              fieldName="interest"
                              handleBlur={handleBlur}
                              hideFormGroupDiv={true}
                              handleChange={handleChange}
                              disabled={!this.state.isInterestFieldEnabled}
                              hideLabel={true}
                              inputContainerClass="col-4 pl-0"
                              touched={touched}
                              value={values.interest}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <FormGroupDatePicker
                    errors={errors}
                    fieldName="dateAdd"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    inputContainerClass="col-12 col-sm-8 col-md-7 icon-calendar"
                    label={TranslationService.translateModule('CaseInfoInvoiceDateAdd', ModuleNamesList.CreateNewCase)}
                    labelClass="col-sm-4 col-md-5"
                    touched={touched}
                    required={true}
                    value={values.dateAdd}
                  />

                  <FormGroupDatePicker
                    errors={errors}
                    fieldName="dateDue"
                    handleChange={handleChange}
                    label={TranslationService.translateModule('CaseInfoInvoiceDateDue', ModuleNamesList.CreateNewCase)}
                    handleBlur={handleBlur}
                    touched={touched}
                    labelClass="col-sm-4 col-md-5"
                    inputContainerClass="col-12 col-sm-8 col-md-7 icon-calendar"
                    required={true}
                    value={values.dateDue}
                  />

                  {this.props.claimInfo.caseType === CaseType.CollectionCase && (
                    <>
                      <FormGroupDatePicker
                        errors={errors}
                        fieldName="dateCollectionNotice"
                        handleChange={handleChange}
                        label={TranslationService.translateModule(
                          'CaseInfoInvoiceDateNoticeCollection',
                          ModuleNamesList.CreateNewCase
                        )}
                        handleBlur={handleBlur}
                        touched={touched}
                        labelClass="col-sm-4 col-md-5"
                        inputContainerClass="col-12 col-sm-8 col-md-7 icon-calendar"
                        required={true}
                        value={values.dateCollectionNotice}
                      />
                      <FormGroupDatePicker
                        errors={errors}
                        fieldName="dateDueNotice"
                        handleChange={handleChange}
                        label={TranslationService.translateModule(
                          'CaseInfoInvoiceDateNoticeDue',
                          ModuleNamesList.CreateNewCase
                        )}
                        handleBlur={handleBlur}
                        touched={touched}
                        labelClass="col-sm-4 col-md-5"
                        inputContainerClass="col-12 col-sm-8 col-md-7 icon-calendar"
                        required={true}
                        value={values.dateDueNotice}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="row justify-content-end">
                <div className="col-auto">
                  <Button
                    id="addNewInvoiceBtn"
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTON_SIZE.SM}
                    label={TranslationService.translateModule('CaseInfoAddInvoice', ModuleNamesList.CreateNewCase)}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  private getDebtorDetails = () => {
    return (this.props.debtorDetails.firstName + ' ' + this.props.debtorDetails.companyNameOrLastName).trimStart();
  };

  private availableCurrencies = (): IDictionary<string> => {
    const currencies = this.props.invoicesSetup.availableCurrencies;
    const mappedCurrencies: IDictionary<string> = {};
    currencies.forEach((currency: ICurrency) => (mappedCurrencies[currency.currencyId] = currency.currencyName));
    return mappedCurrencies;
  };
}

export default NewInvoiceForm;
