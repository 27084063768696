import * as React from 'react';
import * as Reactstrap from 'reactstrap';
import { IModuleProps } from '../../core/types/IModuleProps';
import CreditorsVerificationApiClient from './CreditorsVerificationApiClient';
import { IComponentTable, emptyComponentTable } from '../../core/types/IComponentTable';
import TranslationService from '../../core/services/TranslationService';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import CreditorsVerificationTableConfigurator from './helpers/CreditorsVerificationTableConfigurator';
import { ICreditor } from './types/ICreditor';
import Loader from '../../core/components/Loading/Loader';
import { Status } from '../../core/api/Enums/Status';
import InfoMessageService from '../../core/services/InfoMessageService';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import ErrorResponseHandler from '../../core/services/ErrorResponseHandler';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';

interface IState {
  creditorsTable: IComponentTable<ICreditor>;
  isCreditorDetailsModalOpened: boolean;
  isLoading: boolean;
  isModalLoading: boolean;
  selectedCreditor?: ICreditor;
  hasAccess: boolean;
}

class CreditorsVerifycation extends React.Component<IModuleProps, IState> {
  public state: IState = {
    creditorsTable: { ...emptyComponentTable },
    isCreditorDetailsModalOpened: false,
    isLoading: true,
    isModalLoading: false,
    hasAccess: true,
  };

  public componentDidMount = async () => {
    try {
      const creditorsTable = await CreditorsVerificationApiClient.getCreditorsTable(
        this.props.module.id
      );

      this.setState({
        creditorsTable,
        isLoading: false,
      });
    } catch (error: any) {
      const hasAccess = ErrorResponseHandler.hasAccess(error);

      this.setState({
        isLoading: false,
        hasAccess,
      });
    }
  };

  public render() {
    if (!this.state.isLoading && !this.state.hasAccess) {
      return <NoPermissionsComponent />;
    }

    return (
      <>
        <article className="l-module">
          <section className="l-module__section l-module__section--head mb-3">
            <h1>
              <i className="fas fa-user-friends mr-2" />
              {TranslationService.translateModule(
                'PageHeaderCreditorsVerification',
                ModuleNamesList.CreditorsVerification
              )}
            </h1>
          </section>

          <section
            className={`l-module__section ${
              this.state.isLoading ? 'l-module__section--loading' : ''
            }`}
          >
            {this.state.isLoading && <Loader isLoaded={!this.state.isLoading} />}
            <BootstrapTableContainer
              remote={false}
              wrapperClasses="bt"
              classes="bt__table bt-table bt--small bt--selectable"
              keyField="organizationNumber"
              data={this.state.creditorsTable.values}
              columns={CreditorsVerificationTableConfigurator.getTableColumns(
                this.state.creditorsTable,
                this.openDetailsModal
              )}
            />
          </section>
        </article>

        <Reactstrap.Modal
          isOpen={this.state.isCreditorDetailsModalOpened}
          className="flex-modal modal-module modal-lg"
          toggle={this.toggleModal}
        >
          <Reactstrap.ModalHeader className="modal-header">
            {this.state.selectedCreditor &&
              `${TranslationService.translateModule(
                'ModalHeader',
                ModuleNamesList.CreditorsVerification
              )}: 
                            ${this.state.selectedCreditor.organizationName} (${
                this.state.selectedCreditor.organizationNumber
              })`}
          </Reactstrap.ModalHeader>

          <Reactstrap.ModalBody>
            {this.state.isModalLoading ? (
              <Loader opacity={0.6} />
            ) : (
              <>
                {this.state.selectedCreditor && (
                  <>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <dl className="row">
                          <h6 className="col-12 mb-3">
                            {TranslationService.translateModule(
                              'ModalSectionHeaderCompanyDetails',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </h6>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalOrganizationNumber',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.organizationNumber}
                          </dd>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalOrganizationName',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.organizationName}
                          </dd>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalIndustryCode',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.industryCode}
                          </dd>
                        </dl>
                        <dl className="row">
                          <h6 className="col-12 mb-3">
                            {TranslationService.translateModule(
                              'ModalSectionHeaderCompanyAdress',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </h6>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalOrganizationAddress',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.address.street}{' '}
                            {this.state.selectedCreditor.address.address2}
                            <br />
                            {this.state.selectedCreditor.address.postalCode}{' '}
                            {this.state.selectedCreditor.address.city}
                          </dd>
                        </dl>
                        <dl className="row">
                          <h6 className="col-12 mb-3">
                            {TranslationService.translateModule(
                              'ModalSectionHeaderGeneralInformation',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </h6>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalGIWebsite',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.generalInformation.website}
                          </dd>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalGIEmail',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.generalInformation.email}
                          </dd>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalGIPhoneNumber',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.generalInformation.phoneNumber}
                          </dd>
                        </dl>
                      </div>
                      <div className="col-12 col-sm-6">
                        <dl className="row">
                          <h6 className="col-12 mb-3">
                            {TranslationService.translateModule(
                              'ModalSectionHeaderContactDetails',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </h6>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalCPFullName',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.contactDetails.firstName +
                              ' ' +
                              this.state.selectedCreditor.contactDetails.lastName}
                          </dd>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalCPPhoneNo',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.contactDetails.phoneNumber}
                          </dd>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalCPPEmail',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.contactDetails.email}
                          </dd>
                        </dl>
                        <dl className="row">
                          <h6 className="col-12 mb-3">
                            {TranslationService.translateModule(
                              'ModalSectionHeaderAccountancyInformation',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </h6>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalAccountNumber',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.accountancyInformation.accountNumber}
                          </dd>
                          <dt className="col-12 col-sm-6">
                            {TranslationService.translateModule(
                              'ModalVatLiable',
                              ModuleNamesList.CreditorsVerification
                            )}
                          </dt>
                          <dd className="col-12 col-sm-6">
                            {this.state.selectedCreditor.accountancyInformation.vatLiable
                              ? TranslationService.translate('Yes')
                              : TranslationService.translate('No')}
                          </dd>
                        </dl>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-12">
                        <button
                          className={`btn btn--normal btn-color-1 btn-sm ml-1 float-left`}
                          onClick={this.toggleModal}
                        >
                          {TranslationService.translate('Cancel')}
                        </button>
                        <button
                          className={`btn btn--normal btn-color-2 btn-sm ml-1 float-right`}
                          onClick={() => this.makeVerified()}
                        >
                          {TranslationService.translateModule(
                            'MakeVerified',
                            ModuleNamesList.CreditorsVerification
                          )}
                        </button>
                        <button
                          className={`btn btn--normal btn-color-3 btn-sm float-right`}
                          onClick={() => this.makeDeclined()}
                        >
                          {TranslationService.translateModule(
                            'Decline',
                            ModuleNamesList.CreditorsVerification
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </Reactstrap.ModalBody>
        </Reactstrap.Modal>
      </>
    );
  }

  toggleModal = () => {
    this.setState({ isCreditorDetailsModalOpened: !this.state.isCreditorDetailsModalOpened });
  };

  private makeVerified = async () => {
    this.setState({ isModalLoading: true });
    try {
      const result = await CreditorsVerificationApiClient.makeVerified(
        this.state.selectedCreditor!.organizationNumber,
        this.props.module.id
      );
      if (result.status === Status.Success) {
        InfoMessageService.success(
          TranslationService.translateModule(
            'CreditorValidatedSuccessfully',
            ModuleNamesList.CreditorsVerification
          )
        );
        const tableValues = this.state.creditorsTable.values;
        tableValues.splice(tableValues.indexOf(this.state.selectedCreditor!), 1);

        this.setState({
          isCreditorDetailsModalOpened: false,
          creditorsTable: { ...this.state.creditorsTable, values: tableValues },
        });
      } else {
        InfoMessageService.displayActionStatus(result);
      }
    } finally {
      this.setState({ isModalLoading: false });
    }
  };

  private makeDeclined = async () => {
    this.setState({ isModalLoading: true });
    try {
      const result = await CreditorsVerificationApiClient.makeDeclined(
        this.state.selectedCreditor!.organizationNumber
      );
      if (result.status === Status.Success) {
        InfoMessageService.success(
          TranslationService.translateModule(
            'CreditorDeclinedSuccessfully',
            ModuleNamesList.CreditorsVerification
          )
        );
        const tableValues = this.state.creditorsTable.values;
        tableValues.splice(tableValues.indexOf(this.state.selectedCreditor!), 1);

        this.setState({
          isCreditorDetailsModalOpened: false,
          creditorsTable: { ...this.state.creditorsTable, values: tableValues },
        });
      } else {
        InfoMessageService.displayActionStatus(result);
      }
    } finally {
      this.setState({ isModalLoading: false });
    }
  };

  private openDetailsModal = (selectedCreditor: ICreditor) => {
    this.setState({
      isCreditorDetailsModalOpened: true,
      selectedCreditor,
    });
  };
}

export default CreditorsVerifycation;
