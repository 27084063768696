import React from 'react';
import { DropdownType } from '../types/DropdownType';
import styles from '../Dropdown.module.scss';
// @ts-ignore
import classNames from 'classnames';
import { useDropdownContext } from '../context/DropdownContext';

type DropdownListItemPropsType = {
    className?: string;
    /**
     * Just a callback
     */
    onClick: () => void;
};

const DropdownListItem = (props: DropdownType & DropdownListItemPropsType) => {
    const { toggle } = useDropdownContext()

    const handleOnClick = () => {
        if (toggle) {
            toggle();
        }

        props.onClick();
    }

    return (
        <li className={classNames(styles.dropdownListItem, props.className)} onClick={handleOnClick}>
            {props.children}
        </li>
    );
};

export default React.memo(DropdownListItem);
