import React, { PureComponent } from 'react';
import * as Reactstrap from 'reactstrap';

type Props = {
  isOpen: boolean;
  modalData: {
    className?: string;
    headerIcon?: string;
    headerText?: string;
    content: any;
  };
  onClose?: () => void;
};

type State = {
  isOpen: boolean;
};

class Modal extends PureComponent<Props, State> {
  state: State = {
    isOpen: false,
  };

  render() {
    return (
      <>
        <Reactstrap.Modal
          isOpen={this.props.isOpen}
          className={
            'c-modal' + (this.props.modalData.className ? ` ${this.props.modalData.className}` : '')
          }
        >
          {this.props.modalData.headerText && (
            <Reactstrap.ModalHeader toggle={this.props.onClose} className="c-modal__header">
              {this.props.modalData.headerIcon && <i className={this.props.modalData.headerIcon} />}

              {this.props.modalData.headerText}
            </Reactstrap.ModalHeader>
          )}

          <Reactstrap.ModalBody>{this.props.modalData.content}</Reactstrap.ModalBody>
        </Reactstrap.Modal>
      </>
    );
  }
}

export default Modal;
