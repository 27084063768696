import React from 'react';

type ContextProps = { 
    isOpen: boolean,
    currentOpen: number,
    toggle: (currentOpen: number) => void
}
const AccordionContext = React.createContext<Partial<ContextProps>>({});

const useAccordionContext = () => {
    const context = React.useContext(AccordionContext);

    if (!context) {
      throw new Error(
        `Accordion compound components cannot be rendered outside the Accordion component`,
      )
    }

    return context
}

export {
    AccordionContext,
    useAccordionContext
} 